import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSchoolList() {
  // Use toast
  const toast = useToast()

  const refLicencesList = ref(null)

  const refRegisterLicenseForm = ref(null)
  const refUpdateLicenseForm = ref(null)

  const refInvoiceForm = ref(null)

  const refEnterPhoneNumberForm = ref(null)

  const showPaymentModal = ref(false)
  const paymentInvoiceId = ref('')
  const paymentPhoneNumber = ref('')

  const checkPaymentModal = ref(false)
  const checkPaymentInvoiceId = ref('')

  const isLoading = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'license_id' },
    { key: 'business_name' },
    { key: 'tariff' },
    { key: 'valid_until' },
    { key: 'actions' },
  ]
  const psvTableColumns = [
    { key: 'license_id' },
    { key: 'sacco_name' },
    { key: 'tariff' },
    { key: 'valid_until' },
    { key: 'actions' },
  ]
  const per_page = ref(10)
  const totalLicences = ref(0)
  const current_page = ref(1)
  const per_pageOptions = [5, 10, 15, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const addSchoolName = ref('')
  const addSchoolAddress = ref('')
  const schoolId = ref('')

  const invoiceData = ref(null)
  const licenseData = ref(null)

  const addLicenseModal = ref(false)
  const addLicenseBusinessName = ref('')
  const addLicenseRegistrationNumber = ref('')
  const addLicenseKraPin = ref('')
  const addLicenseJurisdiction = ref([])
  const addLicenseBusinessType = ref('')
  const addLicenseItems = ref([])
  const updateLicenseCounty = ref('')
  const updateLicenseStreetName = ref('')
  const updateLicenseTown = ref('')
  const updateLicenseBuildingName = ref('')

  const showInvoiceModal = ref(false)
  const totalAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refLicencesList.value
      ? refLicencesList.value.localItems.length
      : 0
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalLicences.value,
    }
  })

  const refetchData = () => {
    refLicencesList.value.refresh()
  }

  watch([current_page, per_page, searchQuery], () => {
    refetchData()
  })

  const fetchLicences = (ctx, callback) => {
    store
      .dispatch('licenses/fetchLicences', {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: licences, total } = response.data.data
        callback(licences)
        totalLicences.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching licences',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchPsvLicenses = (ctx, callback) => {
    store
      .dispatch('licenses/fetchPsvLicenses', {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: licenses, total } = response.data.data
        callback(licenses)
        totalLicences.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching licenses',
            icon: 'AlertTrianleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchBroadcastingLicenses = (ctx, callback) => {
    store.dispatch('licenses/fetchBroadcastingLicenses', {
      q: searchQuery.value,
      per_page: per_page.value,
      page: current_page.value,
    })
      .then(response => {
        const { data: licenses, total } = response.data.data
        callback(licenses)
        totalLicences.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching licenses',
            icon: 'AlertTrianleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const storeNewApplication = () => {
    refRegisterLicenseForm.value.validate().then(success => {
      if (success) {
        isLoading.value = true
        store
          .dispatch('licenses/newLicenseApplication', {
            business_name: addLicenseBusinessName.value,
            registration_number: addLicenseRegistrationNumber.value,
            kra_pin: addLicenseKraPin.value,
            jurisdiction: addLicenseJurisdiction.value,
            business_type: addLicenseBusinessType.value,
            license_items: addLicenseItems.value,
          })
          .then(response => {
            router.push({
              name: 'invoice-view',
              params: { id: response.data.data.invoice_id },
            })
            // invoiceData.value = response.data.data
            // addLicenseModal.value = false
            // showInvoiceModal.value = true
            // paymentInvoiceId.value = invoiceData.value.invoice_id
            // paymentPhoneNumber.value = JSON.parse(localStorage.getItem('user')).phone_number
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    })
  }
  const updateLicenseDetails = () => {
    isLoading.value = true
    refUpdateLicenseForm.value.validate().then(success => {
      if (success) {
        isLoading.value = true
        store
          .dispatch('licenses/updateLicense', {
            license_id: licenseData.value.license_id,
            business_county: updateLicenseCounty.value,
            business_street_name: updateLicenseStreetName.value,
            business_building_name: updateLicenseBuildingName.value,
            business_town: updateLicenseTown.value,
          })
          .then(response => {
            licenseData.value = response.data.data
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating licence',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    })
  }

  const processPayment = () => {
    refEnterPhoneNumberForm.value.validate().then(success => {
      if (success) {
        isLoading.value = true
        store
          .dispatch('invoice/processPayment', {
            invoice_id: paymentInvoiceId.value,
            phone_number: paymentPhoneNumber.value,
          })
          .then(response => {
            showPaymentModal.value = false
            checkPaymentInvoiceId.value = paymentInvoiceId.value
            checkPaymentModal.value = true
            toast({
              component: ToastificationContent,
              props: {
                title: response.data.data,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.data,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            isLoading.value = false
          })
      }
    })
  }

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Paid') return 'success'
    if (status === 'Pending') return 'secondary'
    return 'danger'
  }

  const licenseIsExpired = date => {
    const d1 = new Date()
    const d2 = new Date(date)
    if (d1 > d2) {
      return true
    }
    return false
  }

  const checkPayment = () => {
    isLoading.value = true
    store
      .dispatch('invoice/checkPayment', {
        invoice_id: checkPaymentInvoiceId.value,
      })
      .then(response => {
        refetchData()
        checkPaymentModal.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    fetchLicences,
    fetchPsvLicenses,
    fetchBroadcastingLicenses,
    storeNewApplication,
    tableColumns,
    psvTableColumns,
    per_page,
    current_page,
    totalLicences,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLicencesList,
    refetchData,
    schoolId,
    addSchoolAddress,
    addSchoolName,

    invoiceData,
    licenseData,

    refRegisterLicenseForm,
    refInvoiceForm,

    addLicenseModal,
    addLicenseBusinessName,
    addLicenseRegistrationNumber,
    addLicenseKraPin,
    addLicenseJurisdiction,
    addLicenseBusinessType,
    addLicenseItems,
    refUpdateLicenseForm,
    updateLicenseBuildingName,
    updateLicenseCounty,
    updateLicenseStreetName,
    updateLicenseTown,
    updateLicenseDetails,

    showInvoiceModal,
    totalAmount,

    isLoading,

    showPaymentModal,
    paymentInvoiceId,
    paymentPhoneNumber,
    processPayment,

    checkPaymentModal,
    checkPaymentInvoiceId,
    checkPayment,
    resolveInvoiceStatusVariantAndIcon,
    licenseIsExpired,
  }
}
